<template>
    <div style="padding: 0 12px;box-sizing: border-box">
        <div class="game-body">
            <div class="body-h">
                <div class="noselect">{{ title }}</div>
            </div>
            <div class="hot-list">
                <div
                    class="hot-card pointer"
                    v-for="items in gameList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                </div>
                <span v-for="item in 12" :key="item"></span>
            </div>
        </div>
        <div class="game-body" v-if="showMore">
            <div class="body-h">
                <div class="noselect">more fun games</div>
            </div>
            <div class="hot-list">
                <div
                    class="hot-card pointer"
                    v-for="items in moreList"
                    :key="items.id"
                    @click="goDetails(items)"
                >
                    <img :src="items.logo" alt="">
                    <div class="title no-warp">{{items.title}}</div>
                </div>
                <span v-for="item in 12" :key="item"></span>
            </div>
        </div>
        <div class="class-info" v-if="!showMore">
            {{ intro }}
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import {scrollToTop} from '@/utils/api'
import {getGameByKey, searchGame, getGame} from "@/api/games"
import classInfo from "@/utils/classInfo"

export default {
    data() {
        return {
            title: '',
            gameList: [],
            funGame: [],
            moreList: [],
            intro: '',
            showMore: false
        }
    },
    mounted() {
        scrollToTop(0);
        eventBus.$on('searchgame', name => {
            console.log(name)
            let {id, key} = name;
            this.getGameList(id, key)
        })
        let {id, key} = this.$route.query;
        this.getGameList(id, key);
    },
    methods: {
        goDetails(item) {
            this.$router.push({
                path: '/details',
                query: {
                    id: item.id
                }
            })
        },
        async getGameList(id, key) {
            let games = [];
            let title = '';
            if (id == 'search') {
                games = await searchGame({q: key});
                title = `search found "${key}"`;
                this.moreList = await getGame({count: 20});
                this.showMore = true;
            } else if (id == 'more') {
                if (key === 'All') {
                    games = await getGame();
                } else {
                    this.intro = classInfo.find(item => item.name === key).intro;
                    games = await getGameByKey({category: key});
                }
                title = key + ' games';
                this.showMore = false;
            } else {
                console.log(key);
            }
            this.gameList = games;
            this.title = title;
        }
    },
}
</script>

<style lang="less" scoped>
.noselect {
    -moz-user-select: none; /*火狐*/

    -webkit-user-select: none; /*webkit浏览器*/

    -ms-user-select: none; /*IE10*/

    -khtml-user-select: none; /*早期浏览器*/

    user-select: none;
}

.pointer {
    cursor: pointer;
}

.no-warp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.game-body {
    margin: 17px 0 14px;

    .body-h {
        box-sizing: border-box;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        display: flex;
        justify-content: space-between;
    }

    .body-b {
        margin-top: 9px;
        overflow: auto;
        white-space: nowrap;
        @media (min-width: 768px) {
            &::-webkit-scrollbar {
                height: 7px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                background: rgba(0, 0, 0, 0.2);
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 0;
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .hot-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .hot-card {
            width: 81px;
            margin-bottom: 9px;

            img {
                width: 81px;
                height: 81px;
                border-radius: 11px;
            }

            .title {
                height: 18px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 13px;
                color: #000000;
                line-height: 18px;
                text-align: left;
                font-style: normal;
            }
        }

        span {
            width: 81px;
        }
    }
}

.class-info {
    padding: 8px 12px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

</style>